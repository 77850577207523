import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "align-top" }
const _hoisted_2 = { class: "text-primary font-bold" }
const _hoisted_3 = { class: "flex flex-col pb-6" }
const _hoisted_4 = { class: "text-primary font-bold" }
const _hoisted_5 = { class: "mt-3 ml-2 font-medium" }
const _hoisted_6 = { class: "mt-2 ml-2 text-secondary font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", _hoisted_2, _toDisplayString($props.letter) + ")", 1),
    _createElementVNode("td", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, _toDisplayString($props.advice), 1),
      _createElementVNode("span", _hoisted_5, " Beispiel: " + _toDisplayString($props.badExample), 1),
      _createElementVNode("span", _hoisted_6, " EINFACHER: " + _toDisplayString($props.goodExample), 1)
    ])
  ]))
}