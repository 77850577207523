
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton
} from '@ionic/vue'

import LanguageAdvice from '../components/LanguageAdvice.vue'

export default {
  name: 'SimpleLanguage',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    LanguageAdvice,
    IonButtons,
    IonBackButton
  }
}
